<template>
  <el-dialog
    :title="type==='create'?'新增':'编辑'"
    :visible.sync="visible"
    custom-class="createDialog dataM"
    width="600px"
    :close-on-click-modal="false"
    @close="handleClose"
    >
    <el-form :model="form" :rules="rules" ref="ruleForm" class="dialogContent" :show-message="false">
      <div class="left">
        <el-form-item prop="equipmentNo" class="item" label="设备编号">
          <el-input v-model.trim="form.equipmentNo" clearable/>
        </el-form-item>
        <el-form-item prop="equipmentName" class="item" label="名称">
          <el-input v-model.trim="form.equipmentName" clearable/>
        </el-form-item>
        <el-form-item prop="equipmentParameters" class="item" label="参数">
          <el-input v-model.trim="form.equipmentParameters" clearable/>
        </el-form-item>
        <el-form-item prop="workshiftSchemeName" class="item" label="日历方案">
          <el-select v-model="form.shiftId" clearable>
            <el-option v-for="(v,i) in maintenancePlanList" :key="i" :value="v.shiftId" :label="v.workshiftSchemeName">
              {{v.workshiftSchemeName}}
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="right">
        <el-form-item prop="equipmentStatus" class="item" label="设备状态">
          <el-select v-model="form.equipmentStatus">
            <el-option :value="1" label="正常"/>
            <el-option :value="2" label="故障"/>
          </el-select>
        </el-form-item>
        <el-form-item prop="maintenancePlan" class="item" label="保养计划">
          <el-date-picker v-model="form.maintenancePlan" value-format="yyyy-MM-dd"/>
        </el-form-item>
        <el-form-item prop="maintenanceDuration" class="item" label="保养时长">
          <el-time-picker
            v-model="form.maintenanceDuration"
            value-format="HH:mm"
            format="HH:mm"
            :picker-options="{
              selectableRange: '00:00:00 - 23:59:59'
            }"
          />
        </el-form-item>
        <el-form-item prop="maintenanceItems" class="item" label="保养项目">
          <div v-for="(item,index) in form.maintenanceItems" :key="index">
            <el-input v-model.trim="item.value" style="margin:10px 0px" clearable/>
          </div>
          <el-button icon="el-icon-plus" style="width:100%" @click="addItem()"></el-button>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose()">取消</el-button>
      <el-button type="primary" @click="handleSure()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { 
  addTestEquipment,
  editTestEquipment,
  getWorkshift
  } from '@/api/data.js'
export default {
  name:'createStaff',
  props:{
    currentData:{
      require:false,
      default:()=>{}
    },
    createVisible:{
      require:true,
      default:false
    },
    type:{
      default:'edit'
    }
  },
  data(){
    return{
      that:this,
      form:{
        equipmentNo:'',
        equipmentName:'',
        equipmentStatus:'',
        equipmentParameters:'',
        category:'',
        workshiftSchemeName:'',
        shiftId:'',
        maintenancePlan:null,
        maintenanceDuration:'',
        maintenanceItems:[{
          value:''
        }]
      },
      rules:{
        equipmentNo:[
          { required: true, message: '请输入设备编号', trigger: 'change' },
        ],
        equipmentName:[
          {required: true, message: '请输入设备名称', trigger: 'change'}
        ],
        equipmentParameters:[
          {required: true, message: '请输入设备参数', trigger: 'change'}
        ],
        equipmentStatus:[
          {required: true, message: '请选择设备状态', trigger: 'change'}
        ]
      },
      categoryList:['数车','数铣','三坐标','线切割'],
      maintenancePlanList:[],
    }
  },
  computed:{
    visible:{
      get(){
        return this.createVisible
      },
      set(){}
    },
  },
  watch:{
    createVisible:{
      handler(val){
        if(val){
          this.getWorkList()
          if(this.type === 'edit' && JSON.stringify(this.currentData) !== '{}'){
            let maintenanceItems = this.currentData.maintenanceItems
            this.form = {
              ...this.currentData,
              maintenanceItems:maintenanceItems.length ? maintenanceItems.map(item=>{
                return {
                  value:item
                }
              }) : [{value:''}]
            }
          }
        }
      },
      immediate:true
    },
    // currentData(){
    //   if(this.type === 'edit' && JSON.stringify(this.currentData) !== '{}'){
    //     let maintenanceItems = this.currentData.maintenanceItems
    //     this.form = {
    //       ...this.currentData,
    //       maintenanceItems:maintenanceItems.length ? maintenanceItems.map(item=>{
    //         return {
    //           value:item
    //         }
    //       }) : [{value:''}]
    //     }
    //   }
    // }
  },
  methods:{
    getWorkList(){
      let params = {
        page:1,
        size:999
      }
      getWorkshift(params).then(res=>{
        if(res.code === 0){
          this.maintenancePlanList = res.data.records
        }else{
          this.$message({
            message:res.msg || '接口出错',
            showClose: true,
            duration: 0,
            type:'error'
          })
        }
      }).catch(()=>{
        this.$message({
          message:'接口出错',
          showClose: true,
          duration: 0,
          type:'error'
        })
      })
    },
    handleClose(){
      this.$emit('update:createVisible',false)
    },
    handleSure(){
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.handleSubmit()
        } else {
          this.$message({
            message:'请补充完整信息',
            showClose: true,
            duration: 0,
            type:'error'
          })
          return false;
        }
      });
    },
    handleSubmit(){
      let params = {
        ...this.form,
        maintenanceItems:this.form.maintenanceItems.map(v=>v.value),
        workshiftSchemeName:this.maintenancePlanList.find(v=>v.shiftId == this.form.shiftId)?.workshiftSchemeName || ''
      }
      let Api = this.type==='create'? addTestEquipment : editTestEquipment
      Api(params).then(res=>{
        if(res.code == 0){
          this.$emit('update:createVisible',false)
          this.$emit('handleSearch')
          this.$message.success('操作成功')
        }else{
          this.$message({
            message:res.msg || '接口出错',
            showClose: true,
            duration: 0,
            type:'error'
          })
        }
      })
    },
    //添加保养项目
    addItem(){
      this.form.maintenanceItems.push({value:""})
    }
  }
}
</script>

<style scoped lang="scss">

</style>
<style lang="scss">
.createDialog{
  .el-form-item{
    margin-bottom:10px!important;
  }
  .el-form-item__label{
    font-size: 14px;
    line-height: 28px!important;
  }
  .el-select,.el-input{
    width: 100%!important;
  }
  .el-form-item.is-error .el-input__inner{
    border-color: #F56C6C!important;
  }
  .dialogContent{
    display:flex;
    .right,.left{
      display: flex;
      flex-direction: column;
      width:48%;
      .item{
        .item2{
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>